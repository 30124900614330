<template>
  <div style="min-height: 750px;" v-if="d_usrShpcrtBundWdm && d_usrShpcrtBundProWdm && d_usrShpcrtBundProPayWdm">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['active_products'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>

    <b-row style="font-size: 16px;">
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
       <b-card no-body>
        <b-card-header header-bg-variant="white" class="p-1">
          <b-row style="margin: 3px;">
            <b-col sm="12" lg="6" style="padding: 2px;">
              <strong> {{ StoreLangTranslation.data['results'][StoreLang] }} </strong>
            </b-col>
            <b-col sm="12" lg="6" style="text-align: right;"></b-col>
          </b-row>
        </b-card-header>
          <template v-if="d_loading">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
          </template>
          <template v-else>
            <b-row>
              <b-col sm="12" lg="12">
                <!-- 
                  d_shopOrderList = [
                    {
                      "product_list": [],
                      "user_shopcart_bundle_data": {},
                      "user_shop_bundle_data": {},
                    }
                  ]
                 -->
                 <b-card no-body v-for="(shop_item, shop_item_index) in d_shopOrderList">
                  <b-card-header header-bg-variant="white" class="p-1">
                     <b-row style="margin: 0px; background-color: #93ffe4; padding: 5px;">
                      <b-col sm="12" lg="4">
                          <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['2'] && shop_item.shop_data.data['0']['2']['lang'] && shop_item.shop_data.data['0']['2']['lang'][StoreLang]">
                            <img :src="shop_item.shop_data.data['0']['2']['lang'][StoreLang]" style="width: 30px; margin-right: 3px;">
                          </template>
                          <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['2'] && shop_item.shop_data.data['0']['2']['val']">
                            <img :src="shop_item.shop_data.data['0']['2']['val']" style="width: 30px; margin-right: 3px;">
                          </template>
                          <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['1'] && shop_item.shop_data.data['0']['1']['lang'] && shop_item.shop_data.data['0']['1']['lang'][StoreLang]">
                            <strong :title="shop_item.shop_data.data['0']['1']['lang'][StoreLang]">{{ shop_item.shop_data.data['0']['1']['lang'][StoreLang].length > 30 ? shop_item.shop_data.data['0']['1']['lang'][StoreLang].substring(0,15) + '...' : shop_item.shop_data.data['0']['1']['lang'][StoreLang] }}</strong>
                          </template>
                          <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['1'] && shop_item.shop_data.data['0']['1']['val']">
                            <strong :title="shop_item.shop_data.data['0']['1']['val']">{{ shop_item.shop_data.data['0']['1']['val'].length > 30 ? shop_item.shop_data.data['0']['1']['val'].substring(0,15) + '...' : shop_item.shop_data.data['0']['1']['val'] }}</strong>
                          </template>
                      </b-col>
                      <b-col sm="12" lg="2">
                        <i class="fa fa-envelope"></i>
                          <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['29'] && shop_item.shop_data.data['0']['29']['lang'] && shop_item.shop_data.data['0']['29']['lang'][StoreLang]">
                            <strong>{{ shop_item.shop_data.data['0']['29']['lang'][StoreLang] }}</strong>
                          </template>
                          <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['29'] && shop_item.shop_data.data['0']['29']['val']">
                            <strong>{{ shop_item.shop_data.data['0']['29']['val'] }}</strong>
                          </template>
                      </b-col>
                      <b-col sm="12" lg="2">
                        <i class="fa fa-mobile-phone"></i>
                          <template v-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['30'] && shop_item.shop_data.data['0']['30']['lang'] && shop_item.shop_data.data['0']['30']['lang'][StoreLang]">
                            <strong>{{ shop_item.shop_data.data['0']['30']['lang'][StoreLang] }}</strong>
                          </template>
                          <template v-else-if="shop_item.shop_data.data && shop_item.shop_data.data['0'] && shop_item.shop_data.data['0']['30'] && shop_item.shop_data.data['0']['30']['val']">
                            <strong>{{ shop_item.shop_data.data['0']['30']['val'] }}</strong>
                          </template>
                      </b-col>
                    </b-row>
                  </b-card-header>
                  <b-card-body>
                    <template v-for="(bundle_item, bundle_item_index) in shop_item.bundle_list" v-if="f_searchUserShopBundle(bundle_item)">
                       <b-row @mouseover="d_hoverIndex = bundle_item_index" @mouseleave="d_hoverIndex = ''" :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; font-weight: 900; background: #f7e48f;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; font-weight: 900; background: #f7e48f;'">
                        <b-col sm="12" lg="8">
                          <template v-if="bundle_item.user_shop_bundle_data.data && bundle_item.user_shop_bundle_data.data['0'] && bundle_item.user_shop_bundle_data.data['0']['1'] && bundle_item.user_shop_bundle_data.data['0']['1']['lang'] && bundle_item.user_shop_bundle_data.data['0']['1']['lang'][StoreLang]">
                            <span :style="d_hoverIndex === bundle_item_index ? ' color: rgb(252, 0, 153, 1);' : ''">{{ bundle_item.user_shop_bundle_data.data['0']['1']['lang'][StoreLang] }}</span>
                          </template>
                          <template v-else-if="bundle_item.user_shop_bundle_data.data && bundle_item.user_shop_bundle_data.data['0'] && bundle_item.user_shop_bundle_data.data['0']['1'] && bundle_item.user_shop_bundle_data.data['0']['1']['val']">
                            <span :style="d_hoverIndex === bundle_item_index ? ' color: rgb(252, 0, 153, 1);' : ''">{{ bundle_item.user_shop_bundle_data.data['0']['1']['val'] }}</span>
                          </template>
                        </b-col>
                        <b-col sm="12" lg="2">
                          {{ f_calculateProductTotalPrice(bundle_item.product_list) }}
                        </b-col>
                        <b-col sm="12" lg="2">
                            <template v-if="bundle_item.user_shopcart_bundle_data.data['0']['0'] && bundle_item.user_shopcart_bundle_data.data['0']['0']['val'] && bundle_item.user_shopcart_bundle_data.data['0']['0']['val']['value']">
                              {{ f_getOptionLabel(bundle_item.user_shopcart_bundle_data.data['0']['0']['val']['value'], d_usrShpcrtBundWdm['0']['parameters']['0']['options']) }}
                            </template>
                        </b-col>
                      </b-row>
                      <template v-for="(product_item, product_item_index) in bundle_item.product_list" v-if="f_showCheckProduct(product_item.product)">
                        <b-row :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; margin-left: 10px;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; margin-left: 10px;'">
                          <b-col sm="12" lg="6">
                            <i @click="f_openProductIngredients(product_item.product)" style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 20px; background-color: lightblue; text-align: center;">{{ (product_item_index + 1).toString() }}</i>
                            <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['lang'] && product_item.product.data['0']['1']['lang'][StoreLang]">
                              <span>{{ product_item.product.data['0']['1']['lang'][StoreLang] }}</span>
                            </template>
                            <template v-else-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['1'] && product_item.product.data['0']['1']['val']">
                              <span>{{ product_item.product.data['0']['1']['val'] }}</span>
                            </template>
                          </b-col>
                          <b-col sm="12" lg="2"> {{ f_calculateProductTotalPrice([product_item]) }} </b-col>
                          <b-col sm="12" lg="2">
                            <template v-if="product_item.product.data['0']['0'] && product_item.product.data['0']['0']['val'] && product_item.product.data['0']['0']['val']['value']">
                                  {{ f_getOptionLabel(product_item.product.data['0']['0']['val']['value'], d_usrShpcrtBundProWdm['0']['parameters']['0']['options']) }}
                              </template>
                          </b-col>
                          <b-col sm="12" lg="2">
                            <b-dropdown variant="white" size="sm" style="width: 100%; float: left;">
                              <template slot="button-content">
                                <i class="fa fa-list-alt"></i>
                              </template>
                              <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['54'] && product_item.product.data['0']['54']['val'] && product_item.product.data['0']['54']['val']['value'] === '4'">
                                <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['55'] && product_item.product.data['0']['55']['val']">
                                  <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['56'] && product_item.product.data['0']['56']['val']">
                                    <b-dropdown-item @click="f_goToUserStatisticsViewProducts(bundle_item.user_shopcart_bundle_data.client_id, product_item.product.client_id, product_item.product.data['0']['55']['lang'][StoreLang], product_item.product.data['0']['56']['lang'][StoreLang])"><i class="fa fa-area-chart"></i> {{ StoreLangTranslation.data['statistics_result_view'][StoreLang] }} </b-dropdown-item>
                                  </template>
                                </template>
                              </template>
                              <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['54'] && product_item.product.data['0']['54']['val'] && product_item.product.data['0']['54']['val']['value'] === '5'">
                                <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['55'] && product_item.product.data['0']['55']['val']">
                                  <b-dropdown-item @click="f_goToUserDatasetFilterProducts(bundle_item.user_shopcart_bundle_data.client_id, product_item.product.client_id, product_item.product.data['0']['55']['lang'][StoreLang])"><i class="fa fa-pie-chart"></i> {{ StoreLangTranslation.data['dataset_filter'][StoreLang] }} </b-dropdown-item>
                                </template>
                              </template>
                              <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['54'] && product_item.product.data['0']['54']['val'] && product_item.product.data['0']['54']['val']['value'] === '6'">
                                <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['55'] && product_item.product.data['0']['55']['val']">
                                  <b-dropdown-item @click="f_goToUserDatasetStatisticsQueryProducts(bundle_item.user_shopcart_bundle_data.client_id, product_item.product.client_id, product_item.product.data['0']['55']['lang'][StoreLang])"><i class="fa fa-pie-chart"></i> {{ StoreLangTranslation.data['dataset_stats_query'][StoreLang] }} </b-dropdown-item>
                                </template>
                              </template>
                              <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['54'] && product_item.product.data['0']['54']['val'] && product_item.product.data['0']['54']['val']['value'] === '7'">
                                <template v-if="product_item.product.data && product_item.product.data['0'] && product_item.product.data['0']['55'] && product_item.product.data['0']['55']['val'] && product_item.product.data['0']['57'] && product_item.product.data['0']['57']['val']">
                                  <b-dropdown-item @click="f_goToUserMachineLearningQueryProducts(bundle_item.user_shopcart_bundle_data.client_id, product_item.product.client_id, product_item.product.data['0']['55']['lang'][StoreLang], product_item.product.data['0']['57']['lang'][StoreLang])"><i class="fa fa-pie-chart"></i> {{ StoreLangTranslation.data['machine_learning_query'][StoreLang] }} </b-dropdown-item>
                                </template>
                              </template>
                            </b-dropdown>
                          </b-col>
                        </b-row>
                        <template v-for="(payment_item, payment_index) in product_item.payment_list">
                            <b-row :style="StoreDevice.isMobile ? 'cursor: pointer; border-bottom: solid 1px #dcdcdc; margin-left: 30px; font-size: 12px;' : 'cursor: pointer; margin: 1px; border-bottom: solid 1px #dcdcdc; margin-left: 30px; font-size: 12px;'">
                            <b-col sm="12" lg="1" style="padding: 2px; text-align: right;">
                              <i @click="f_openProductPaymentIngredients(payment_item)" style="border-radius: 10px; padding-right: 5px; margin: 3px; padding-left: 5px; width: 20px; background-color: #ade6ad; text-align: center;">{{ (payment_index + 1).toString() }}</i>
                            </b-col>
                            <b-col sm="12" lg="1" style="padding: 2px;">
                              <template v-if="payment_item.data['0']['1'] && payment_item.data['0']['1']['val'] && payment_item.data['0']['1']['val']['value']">
                                {{ f_getOptionLabel(payment_item.data['0']['1']['val']['value'], d_usrShpcrtBundProPayWdm['0']['parameters']['1']['options']) }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="2" style="padding: 2px;">
                              <template v-if="payment_item.data['0']['2'] && payment_item.data['0']['2']['val'] && payment_item.data['0']['2']['val']['value']">
                                {{ f_getOptionLabel(payment_item.data['0']['2']['val']['value'], d_usrShpcrtBundProPayWdm['0']['parameters']['2']['options']) }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="1" style="padding: 2px;">
                              <template v-if="payment_item.data['0']['4'] && payment_item.data['0']['4']['val']">
                                {{ payment_item.data['0']['4']['val'] }}
                              </template>
                              <template v-if="payment_item.data['0']['3'] && payment_item.data['0']['3']['val'] && payment_item.data['0']['3']['val']['value']">
                                {{ f_getOptionLabel(payment_item.data['0']['3']['val']['value'], d_usrShpcrtBundProPayWdm['0']['parameters']['3']['options']) }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="2">
                              <template v-if="payment_item.data['0']['17'] && payment_item.data['0']['17']['val']">
                                {{ payment_item.data['0']['17']['val'] }}
                              </template>
                            </b-col>
                            <b-col sm="12" lg="2">
                              <template v-if="payment_item.data['0']['0'] && payment_item.data['0']['0']['val'] && payment_item.data['0']['0']['val']['value']">
                                <span style="font-size: 12px;"> {{ f_getOptionLabel(payment_item.data['0']['0']['val']['value'], d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options']) }} </span>
                              </template>
                            </b-col>
                          </b-row>
                        </template>
                      </template>
                    </template>
                  </b-card-body>
                 </b-card>
              </b-col>
            </b-row>
          </template>
          
      </b-card>

      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-modal v-if="d_selectedProductData.show" id="d_selectedProductData_show" v-model="d_selectedProductData.show" scrollable hide-footer :title="StoreLangTranslation.data['product_ingredients'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <shopcard-bundle-product-show :p_productData="d_selectedProductData.data"></shopcard-bundle-product-show>
          </b-col>
        </b-row>
      </p>
      <p>
      <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
          </b-col>
        </b-row>
      </p>
    </b-modal>
    <b-modal v-if="d_selectedProductPaymentData.show" id="d_selectedProductPaymentData_show" v-model="d_selectedProductPaymentData.show" scrollable hide-footer :title="StoreLangTranslation.data['payment_details'][StoreLang]" centered header-bg-variant="info" header-text-variant="dark" size="lg">
      <p>
        <b-row>
          <b-col sm="12" lg="12">
            <shopcard-bundle-product-payment-show :p_paymentData="d_selectedProductPaymentData.data"></shopcard-bundle-product-payment-show>
          </b-col>
        </b-row>
      </p>
      <p>
      <b-row>
          <b-col sm="12" lg="6"></b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
          </b-col>
        </b-row>
      </p>
    </b-modal>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import { ClsRoute } from '@/wam/modules/route';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import {
  default as ShopcardBundleProductShow
} from '@/components/widgets/ShopcardBundleProductShow';
import {
  default as ShopcardBundleProductPaymentShow
} from '@/components/widgets/ShopcardBundleProductPaymentShow';
import { mapGetters } from 'vuex';
export default {
  name: 'user_product_bundles_active',
  computed: mapGetters({
    StoreLang: 'StoreLang',
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
    StoreShopCartOrderList: 'StoreShopCartOrderList',
    StoreCurrencyType: 'StoreCurrencyType',
  }),
  components: {
    PageInfo,
    ShopcardBundleProductShow,
    ShopcardBundleProductPaymentShow,
  },
  props: {},
  data () {
    return {
      d_loading: false,
      d_selectedProductListForPayment: {
        'user_shopcart_bundle_client_id': '',
        'product_list': [],
        'show': false
      },
      d_selectedProductPaymentData: {
        'data': '',
        'show': false
      },
      d_selectedProductData: {
        'data': '',
        'show': false
      },
      d_paymentModal: {
        'data': '',
        'show': false
      },
      d_usrShpcrtBundWdm: '',
      d_usrShpcrtBundProWdm: '',
      d_usrShpcrtBundProPayWdm: '',
      d_currencyType: '0', // 0 tl, 1 dollar
      d_user: '',
      d_hoverIndex: '',
      d_searchText: '',
      d_frontendClientID: '',
      d_frontendList: [],
      d_usrShpBundleList: [],
      d_shopOrderList: []
    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_shpcrt_bund').then(resp_wdm_data => { this.d_usrShpcrtBundWdm = resp_wdm_data; });
    ClsWdm.getLayerWdm('usr_shpcrt_bund_pro').then(resp_wdm_data => { this.d_usrShpcrtBundProWdm = resp_wdm_data; });
    ClsWdm.getLayerWdm('usr_shpcrt_bund_pro_pay').then(resp_wdm_data => { this.d_usrShpcrtBundProPayWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
    this.d_userShopClientID = this.$route.query.user_shop_client_id;
    this.f_userShopCartBundleActiveList();
  },
  beforeMount () {},
  mounted: function () {
    // console.log(this.StoreFrontendSettings);
  },
  methods: {
    f_goToUserDatasetFilterProducts: function (user_shopcart_bundle_client_id, user_shopcart_bundle_product_client_id, project_id) {
      this.$router.push({
        'path': ClsRoute.f_getRoutePath('user_project_dataset_filter', this.StoreLang),
        'query': {
          'project_id': project_id,
          'project_type': 'wanalyzer_dataset_filter',
        }
      });
    },
    f_goToUserDatasetStatisticsQueryProducts: function (user_shopcart_bundle_client_id, user_shopcart_bundle_product_client_id, project_id) {
      this.$router.push({
        'path': ClsRoute.f_getRoutePath('user_project_dataset_statistics_query', this.StoreLang),
        'query': {
          'project_id': project_id,
          'project_type': 'wanalyzer_dataset_stats_query',
        }
      });
    },
    f_goToUserMachineLearningQueryProducts: function (user_shopcart_bundle_client_id, user_shopcart_bundle_product_client_id, project_id, machine_learning_id) {
      this.$router.push({
        'path': ClsRoute.f_getRoutePath('user_project_machine_learning', this.StoreLang),
        'query': {
          'project_id': project_id,
          'project_type': 'wanalyzer_machinelearning_query',
          'ml_id': machine_learning_id
        }
      });
    },
    f_goToUserStatisticsViewProducts: function (user_shopcart_bundle_client_id, user_shopcart_bundle_product_client_id, project_id, stats_id) {
      this.$router.push({
        'path': ClsRoute.f_getRoutePath('user_project_statistics_view', this.StoreLang),
        'query': {
          'project_id': project_id,
          'project_type': 'wanalyzer_stats_view',
          'stats_id': stats_id,
        }
      });
    },
    f_showCheckProduct: function (product_data) {
      let show_product = true;
      try {
        if (product_data.data['0']['48']['val']['value'] === '0') {
          show_product = false;
        }
      } catch(err) {}
      return show_product;
    },
    f_userShopCartBundleActiveList: function () {
      this.d_loading = true;
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      ServiceFunctions.user_shopcart_bundle_active_list(query, data)
        .then(resp => {
          this.d_loading = false;
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            this.d_shopOrderList = resp.data.list;
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
    f_getEligibleBundleProductPaymentStatus: function (pay_data) {
      let pay_last_status = '';
      let pay_option_list = [];
      try {
        pay_last_status = pay_data.data['0']['0']['val']['value'];
      } catch(err) {}
      for (let option_index in this.d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options']) {
        if (pay_last_status !== this.d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options'][option_index].value) {
          pay_option_list.push(this.d_usrShpcrtBundProPayWdm['0']['parameters']['0']['options'][option_index]);
        }
      }
      return pay_option_list;
    },
    f_getEligibleBundleProductStatus: function (product_data) {
      let product_last_status = '';
      let product_option_list = [];
      try {
        product_last_status = product_data.data['0']['0']['val']['value'];
      } catch(err) {}
      for (let option_index in this.d_usrShpcrtBundProWdm['0']['parameters']['0']['options']) {
        if (product_last_status !== this.d_usrShpcrtBundProWdm['0']['parameters']['0']['options'][option_index].value) {
          product_option_list.push(this.d_usrShpcrtBundProWdm['0']['parameters']['0']['options'][option_index]);
        }
      }
      return product_option_list;
    },
    f_getEligibleBundleStatus: function (shopcart_bundle_data) {
      let bundle_last_status = '';
      try {
        bundle_last_status = shopcart_bundle_data.data['0']['0']['val']['value'];
      } catch(err) {}
      let bundle_option_list = [];
      for (let option_index in this.d_usrShpcrtBundWdm['0']['parameters']['0']['options']) {
        if (bundle_last_status !== this.d_usrShpcrtBundWdm['0']['parameters']['0']['options'][option_index].value) {
          bundle_option_list.push(this.d_usrShpcrtBundWdm['0']['parameters']['0']['options'][option_index]);
        }
      }
      return bundle_option_list;
    },
    f_getOptionLabel: function (option_value, options) {
      // console.log('option_value : ', option_value);
      // console.log('options : ', options);
      let option_label = '';
      for (let i in options) {
        if (options[i].value === option_value) {
          option_label = options[i].translation[this.StoreLang];
          break;
        }
      }
      return option_label;
    },
    f_openPaymentScreen: function (product_list, user_shopcart_bundle_client_id) {
      this.d_selectedProductListForPayment.product_list = product_list;
      this.d_selectedProductListForPayment.user_shopcart_bundle_client_id = user_shopcart_bundle_client_id;
      this.d_selectedProductListForPayment.show = true;
    },
    f_openProductIngredients: function (product_data) {
      this.d_selectedProductData.show = true;
      this.d_selectedProductData.data = product_data;
    },
    f_openProductPaymentIngredients: function (payment_data) {
      this.d_selectedProductPaymentData.show = true;
      this.d_selectedProductPaymentData.data = payment_data;
    },
    f_searchUserShopBundle: function (usr_shp_bund) {
      let res = false;
      if (this.d_searchText) {
        if (usr_shp_bund.w_id.toLocaleLowerCase().indexOf(this.d_searchText.toLocaleLowerCase()) !== -1) {
          res = true;
        }
      } else {
        res = true;
      }
      return res;
    },
    f_calculateProductTotalPrice: function (bundle_products) {
      let currency_text = this.StoreCurrencyType.label;
      let total_amount = 0;
      for (let product_index in bundle_products) {
        try {
          for (let currency_amount_index in bundle_products[product_index].product.data['0']['25'].list) {
            let currency_amount_item = bundle_products[product_index].product.data['0']['25'].list[currency_amount_index];
            try {
              if (currency_amount_item['26']['val']['value'] === this.StoreCurrencyType.value) {
                total_amount += parseFloat(currency_amount_item['27']['val']);
              }
            } catch(err) {}
          }
        } catch(err) {}
      }
      return total_amount + ' ' + currency_text;
    },
  },
  watch: {}
};

</script>

